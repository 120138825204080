import * as React from "react";
import Button from "react-bootstrap/Button";
import {
  styledButton,
  styledButtonContainer,
} from "./UnlockPriceButton.module.scss";

export const StyledButton: React.FC<any> = (props) => {
  return (
    <div className={styledButtonContainer}>
      <Button
        className={`${styledButton}  mt-2 ${props.unlockClass}`}
        id={`member-rate-button`}
        size="sm"
        onClick={props.onClick}
        aria-label="Unlock Button"
      >
        {props.children}
      </Button>
    </div>
  );
};
