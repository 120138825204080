import * as React from "react";
import { tooltip } from "./FeeDescriptionTooltip.module.scss";
export const Tooltip: React.FC<any> = React.forwardRef((props, ref) => {
  return (
    <span className={tooltip} {...props} ref={ref}>
      {props.children}
    </span>
  );
});

Tooltip.displayName = "Tooltip";
