import { useAppDispatch, useAppSelector } from ".";
import { Storage } from "../utils/storage";
import { unlockPricing } from "../redux/slices/Rate/rate";

export const useUnlockDiscount = (
  rateType: string
): [boolean, any, any, () => void] => {
  const dispatch = useAppDispatch();

  const isLoggedIn = useAppSelector((state) => state.member.isLoggedIn);

  const hrDiscountUnlockedInStore = useAppSelector(
    (state) => state.show_special_pricing
  );
  const hrDiscountUnlockedInStorage = Storage.GetLocalStorageValue(
    "HR-discount-unlocked"
  );

  const showUnlockButton = rateType === "member" && !isLoggedIn;
  const discountUnlocked =
    hrDiscountUnlockedInStore || hrDiscountUnlockedInStorage;
  const showSpecialPricing = showUnlockButton && discountUnlocked;

  const setShowSpecialPricing = () => {
    dispatch(unlockPricing(true));
  };

  return [
    showUnlockButton,
    discountUnlocked,
    showSpecialPricing,
    setShowSpecialPricing,
  ];
};
