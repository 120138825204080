// extracted by mini-css-extract-plugin
export var alertInfo = "NewSelectRoom-module--alert-info--72f0b";
export var alt = "NewSelectRoom-module--alt--631a5";
export var desktopOnly = "NewSelectRoom-module--desktopOnly--378b9";
export var main = "NewSelectRoom-module--main--44492";
export var mobileOnly = "NewSelectRoom-module--mobileOnly--8a15a";
export var occupancyAlert = "NewSelectRoom-module--occupancyAlert--1b510";
export var promoAlert = "NewSelectRoom-module--promoAlert--80724";
export var roomFilters = "NewSelectRoom-module--roomFilters--c9fbd";
export var selectionTitle = "NewSelectRoom-module--selectionTitle--d0c8f";
export var textLeft = "NewSelectRoom-module--textLeft--5b0c8";
export var updateSearchRoom = "NewSelectRoom-module--updateSearchRoom--4ffdf";
export var visuallyHidden = "NewSelectRoom-module--visuallyHidden--87e60";