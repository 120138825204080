// extracted by mini-css-extract-plugin
export var alt = "UnlockPriceButton-module--alt--1b6e4";
export var desktopOnly = "UnlockPriceButton-module--desktopOnly--9164b";
export var discountUnlockedContainer = "UnlockPriceButton-module--discountUnlockedContainer--9664a";
export var main = "UnlockPriceButton-module--main--45b2b";
export var mobileOnly = "UnlockPriceButton-module--mobileOnly--ea150";
export var styledButton = "UnlockPriceButton-module--styledButton--163f4";
export var styledButtonContainer = "UnlockPriceButton-module--styledButtonContainer--4f4a1";
export var textLeft = "UnlockPriceButton-module--textLeft--435ea";
export var unlockBtnClass = "UnlockPriceButton-module--unlockBtnClass--36585";
export var unlockSuccessClass = "UnlockPriceButton-module--unlockSuccessClass--eee9b";
export var visuallyHidden = "UnlockPriceButton-module--visuallyHidden--7c8f4";