import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { Tooltip } from "./FeeDescriptionTooltipHelpers";

const FeeDescriptionTooltip = ({ feeItems }: any) => {
  const feeItemAvailable = feeItems && feeItems?.length > 0 ? true : false;
  let feeDescription = "";

  feeItemAvailable &&
    feeItems.map((feeItem: any, i: number) => {
      feeDescription += feeItem.description;
      if (i > 0) {
        feeDescription += "<br /><br />";
      }
    });
  return (
    <Tippy
      content={<div dangerouslySetInnerHTML={{ __html: feeDescription }} />}
      theme="bootstrap"
    >
      <Tooltip>
        <FontAwesomeIcon icon={faInfo} className="icon" />
      </Tooltip>
    </Tippy>
  );
};

export default FeeDescriptionTooltip;
