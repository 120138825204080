import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isDate from "date-fns/isDate";
import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import {
  formatDate,
  parseSearchDate,
  searchDateFormat,
} from "../../../services/dates";
import { Storage } from "../../../utils/storage";
import GlobalOccupancy from "../../common/Search/GlobalOccupancy/GlobalOccupancy";
import {
  Dates,
  DropdownText,
  MemberDiscountList,
  OccupancyText,
} from "../../common/Search/GlobalSearch/GlobalSearchHelpers";
import {
  AUTO_LABEL,
  CORPORATE_LABEL,
  GOVERNMENT_LABEL,
  GROUP_LABEL,
  LRR_LABEL,
  OCCUPANCY_LABEL,
  PACKAGE_LABEL,
  PROMO_LABEL,
  RATES_LABEL,
} from "../../common/Search/GlobalSearch/GlobalSearchProps";
import StayDates from "../../common/Search/StayDates/StayDates";
import { updateSearchRoom } from "./NewSelectRoom.module.scss";

const useClickOutSide = (handler: { (): void; (): void }) => {
  const domNodeRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const eventHandler: { (event: MouseEvent): void } = (event: MouseEvent) => {
      if (!domNodeRef.current?.contains(event.target as Node)) {
        handler();
      }
    };
    document?.addEventListener("mousedown", eventHandler);
    return () => {
      document?.removeEventListener("mousedown", eventHandler);
    };
  });
  return domNodeRef;
};

export const UpdateSelectRoomCriteria: React.FC<any> = (props) => {
  const {
    checkout,
    updateSearch,
    isEditReservationModal = false,
    HandleLrrSelect,
  } = props;
  const [checkInDate, updateCheckInDate] = useState(
    parseSearchDate(checkout.Start)
  );
  const [checkOutDate, updateCheckOutDate] = useState(
    parseSearchDate(checkout.End)
  );
  const [showDiscounts, setShowDiscounts] = useState(false);
  const [showOccupancy, setShowOccupancy] = useState(false);
  const [showStayDates, setShowStayDates] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [discount, setDiscount] = useState(checkout.discount);
  const [promotionCode, setPromotionCode] = useState(checkout.promotionCode);
  const [groupCode, setGroupCode] = useState(checkout.groupCode);

  const [showPromoField, setShowPromoField] = useState(
    checkout.promotionCode !== null
  );
  const [showGroupField, setShowGroupField] = useState(
    checkout.groupCode !== null
  );

  const [lrrSelected, setLrrSelected] = useState(
    Storage.GetLocalStorageValue("lrr_selected")
  );

  useEffect(() => {
    const rooms = [];
    const roomKeys = Object.keys(checkout.Rooms || {});
    roomKeys.map((roomKey) => {
      const childrenAges = checkout.Rooms[roomKey].childrenAges
        ? [...checkout.Rooms[roomKey].childrenAges]
        : [];
      let childrenCount = +checkout.Rooms[roomKey].children;
      if (+checkout.Rooms[roomKey].children > 0 && !childrenAges.length) {
        while (childrenCount > 0) {
          childrenAges.push("");
          childrenCount--;
        }
      }
      const room = {
        id: roomKey,
        adults: +checkout.Rooms[roomKey].adults,
        children: +checkout.Rooms[roomKey].children,
        childrenAges: [...childrenAges],
      };
      rooms.push(room);
    });
    setRooms(rooms);
  }, [checkout]);

  const promoValidCodes = useStaticQuery(graphql`
    {
      allPromoCodeValid {
        nodes {
          code
        }
      }
    }
  `);

  const handleUpdateCheckinDate = (
    checkin: React.SetStateAction<Date | undefined | 0>
  ) => {
    if (isDate(checkin)) {
      updateCheckInDate(checkin);
      updateCheckOutDate(undefined);
    }
  };

  const handleUpdateCheckoutDate = (
    checkout: React.SetStateAction<Date | undefined | 0>
  ) => {
    if (isDate(checkout)) {
      updateCheckOutDate(checkout);
    }
  };

  const handleOnStayDatesClick = () => {
    setShowOccupancy(false);
    setShowDiscounts(false);
    setShowStayDates(true);
  };

  const domNodeRef = useClickOutSide(() => {
    setShowStayDates(false);
  });

  const occuRef = useClickOutSide(() => {
    setShowOccupancy(false);
  });
  const handleOccupancyDropdown = () => {
    setShowOccupancy(!showOccupancy);
    if (showDiscounts) {
      setShowDiscounts(false);
    }
  };

  const handleDiscountChange = (eventKey: any) => {
    const discountSelected =
      eventKey !== "LRR" && eventKey !== discount ? eventKey : null;
    setDiscount(discountSelected);
    setPromotionCode(null);
    setGroupCode(null);
    setShowPromoField(false);
    setShowGroupField(false);
    setShowDiscounts(false);

    setLrrSelected(eventKey === "LRR");
    HandleLrrSelect(eventKey === "LRR");
  };

  const handlePromotionCodeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value.length ? event.target.value : null;
    setPromotionCode(value);
    setGroupCode(null);
    setDiscount(null);
  };
  const handlePromotionCodeBlur = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (value !== null) {
      setPromotionCode(value);
    }
  };

  const handlePromoCode = (value: any) => {
    const promoCodes = promoValidCodes?.allPromoCodeValid?.nodes;
    for (let i = 0; i < promoCodes.length; i++) {
      if (promoCodes[i].code == value?.toUpperCase()) {
        setPromotionCode(value);
        setGroupCode(null);
        setDiscount(null);
        return;
      }
    }
    if (value !== null) {
      setPromotionCode(null);
      setGroupCode(value);
      setDiscount(null);
    }
  };

  const handleGroupCodeBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    handlePromoCode(value);
  };

  const handleGroupCodeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value.length ? event.target.value : null;
    handlePromoCode(value);
  };

  const handleDiscountsDropdown = () => {
    if (promotionCode && promotionCode.trim().length < 1) {
      setPromotionCode(null);
      setShowPromoField(false);
    } else if (promotionCode === null && showPromoField) {
      setShowPromoField(false);
    }
    if (groupCode !== null && groupCode.trim().length < 1) {
      setGroupCode(null);
      setShowGroupField(false);
    } else if (groupCode === null && showGroupField) {
      setShowGroupField(false);
    }
    setShowDiscounts(!showDiscounts);
    if (showOccupancy) {
      setShowOccupancy(false);
    }
    // document?.getElementById("dropdown-discounts").focus();
    const element = document?.getElementById("dropdown-discounts");
    if (element !== null) {
      element.focus();
    }
  };

  const handleShowPromo = () => {
    setShowPromoField(true);
    setDiscount(null);
    setGroupCode(null);
    setShowGroupField(false);
  };
  const handleShowGroup = () => {
    setShowGroupField(true);
    setDiscount(null);
    setPromotionCode(null);
    setShowPromoField(false);
  };

  const handleSetOccupancy = (rooms: Room[]) => {
    setRooms(rooms);
  };

  let totalGuests = 0;
  rooms.forEach((room: { adults: any; children: any }) => {
    const roomGuests = room.adults + room.children;
    totalGuests = totalGuests + roomGuests;
  });
  let adultsText = "0 Adults";
  let childsText = "0 Children";
  if (rooms.length > 0) {
    adultsText =
      rooms[0].adults === 1 ? "1 Adult" : `${rooms[0].adults} Adults`;
    childsText =
      rooms[0].children === 1 ? "1 Child" : `${rooms[0].children} Children`;
  }

  let discountToggleText = lrrSelected ? LRR_LABEL : RATES_LABEL;
  if (discount !== null) {
    if (
      discount === "AAA" ||
      discount === "AAAB" ||
      discount === "AAAX" ||
      discount === "AARP"
    ) {
      discountToggleText = AUTO_LABEL;
    } else if (discount === "PKG") {
      discountToggleText = PACKAGE_LABEL;
    } else {
      discountToggleText = discount;
    }
  } else if (promotionCode && promotionCode.length) {
    discountToggleText = `${PROMO_LABEL}: ${promotionCode}`;
  } else if (groupCode !== null && groupCode.length) {
    discountToggleText = `${GROUP_LABEL}: ${groupCode}`;
  }

  const discountRef = useClickOutSide(() => {
    setShowDiscounts(false);
  });
  if (showPromoField) {
    document?.getElementById("corporate-promo")?.removeAttribute("href");
  }

  const isLRR = !discount && !showPromoField && !showGroupField;

  const handleUpdateSearch = () => {
    const index = Object.keys(checkout.Rooms)[0];
    let currentRoom = checkout.Rooms[index];
    currentRoom = {
      ...currentRoom,
      adults: rooms[0].adults,
      children: rooms[0].children,
      childrenAges: [...rooms[0].childrenAges],
    };
    updateSearch({
      ...checkout,
      Rooms: { ...checkout.Rooms, [index]: currentRoom },
      Start: formatDate(checkInDate, searchDateFormat),
      End: formatDate(checkOutDate, searchDateFormat),
      discount: discount,
      promotionCode: promotionCode,
      groupCode: groupCode,
    });
  };

  return (
    <div className={updateSearchRoom}>
      <Row>
        <Dates
          extraClass="col-lg-3"
          ref={domNodeRef}
          isLogo={false}
          isEditReservation={true}
        >
          <StayDates
            from={checkInDate}
            to={checkOutDate}
            checkinDayChange={handleUpdateCheckinDate}
            checkoutDayChange={handleUpdateCheckoutDate}
            shouldHide={showOccupancy || showDiscounts || !showStayDates}
            onDateInputClick={handleOnStayDatesClick}
            isSearchModal={false}
            isOfferModal={false}
            isEditReservationModal={isEditReservationModal}
          />
        </Dates>

        <Col lg={4} className={` d-lg-block occupancy`} ref={occuRef}>
          <Form.Group className="mb-lg-0">
            <Form.Label srOnly>{OCCUPANCY_LABEL}</Form.Label>
            <Dropdown className={`occupancy`} show={showOccupancy}>
              <Dropdown.Toggle
                variant="search-dropdown"
                className="form-control btn-search-dropdown"
                id="dropdown-occupancy"
                onClick={handleOccupancyDropdown}
              >
                <OccupancyText>{`${adultsText} , ${childsText}`}</OccupancyText>
                <FontAwesomeIcon icon="chevron-down" />
              </Dropdown.Toggle>
              <Dropdown.Menu className={`occupancy`}>
                <GlobalOccupancy
                  rooms={rooms}
                  setOccupancy={handleSetOccupancy}
                  isSearchModal={false}
                  setOccupancyDropdown={handleOccupancyDropdown}
                  isEditReservation={true}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Col>

        <Col
          lg={4}
          className={`d-lg-block search-discounts `}
          ref={discountRef}
        >
          <Form.Group controlId="discount" className="mb-lg-0">
            <Form.Label srOnly aria-labelledby="dropdown_discounts">
              Rates
            </Form.Label>
            <Dropdown
              className={`discounts`}
              id="dropdown_discounts"
              show={showDiscounts !== false}
            >
              <Dropdown.Toggle
                variant="search-dropdown"
                className="form-control"
                id="dropdown-discounts"
                onClick={handleDiscountsDropdown}
              >
                <DropdownText discountToggleText={discountToggleText} />
                <FontAwesomeIcon icon="chevron-down" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className={`discounts-members`}>
                  <MemberDiscountList>
                    <li>
                      <Dropdown.Item
                        id={`lrr-label`}
                        eventKey={`LRR`}
                        onSelect={handleDiscountChange}
                        active={isLRR}
                        aria-label={LRR_LABEL}
                      >
                        <span>
                          {LRR_LABEL}
                          {isLRR && <FontAwesomeIcon icon="check" />}
                        </span>
                      </Dropdown.Item>
                    </li>
                    <li>
                      <Dropdown.Item
                        id={`package-label`}
                        eventKey={`PKG`}
                        onSelect={handleDiscountChange}
                        active={discount === `PKG`}
                        aria-label={PACKAGE_LABEL}
                      >
                        <span>
                          {PACKAGE_LABEL}
                          {discount === "PKG" && (
                            <FontAwesomeIcon icon="check" />
                          )}
                        </span>
                      </Dropdown.Item>
                    </li>
                    <li>
                      <Dropdown.Item
                        id={`auto-label`}
                        eventKey={`AAA`}
                        onSelect={handleDiscountChange}
                        active={discount === `AAA` || discount === `AARP`}
                        aria-label={AUTO_LABEL}
                      >
                        <span>
                          {AUTO_LABEL}
                          {discount === "AAA" && (
                            <FontAwesomeIcon icon="check" />
                          )}
                        </span>
                      </Dropdown.Item>
                    </li>
                    <li>
                      <Dropdown.Item
                        id={`government-label`}
                        eventKey={`GOV`}
                        onSelect={handleDiscountChange}
                        active={discount === `GOV`}
                        aria-label={GOVERNMENT_LABEL}
                      >
                        <span>
                          {GOVERNMENT_LABEL}
                          {discount === "GOV" && (
                            <FontAwesomeIcon icon="check" />
                          )}
                        </span>
                      </Dropdown.Item>
                    </li>
                    <li>
                      <Dropdown.Item
                        onSelect={handleShowPromo}
                        active={showPromoField}
                        aria-label={CORPORATE_LABEL}
                        id="corporate-promo"
                      >
                        <span>
                          {CORPORATE_LABEL}
                          {showPromoField && <FontAwesomeIcon icon="check" />}
                        </span>
                        {showPromoField && (
                          <Form.Group
                            controlId={`promotionCode`}
                            className={`discounts-promotion`}
                          >
                            <Form.Label srOnly>{CORPORATE_LABEL}</Form.Label>
                            <Form.Control
                              className="input-wrapper"
                              name={`promotionCode`}
                              placeholder={`Enter code`}
                              onChange={handlePromotionCodeChange}
                              onBlur={handlePromotionCodeBlur}
                              defaultValue={promotionCode ? promotionCode : ""}
                            />
                          </Form.Group>
                        )}
                      </Dropdown.Item>
                    </li>
                    <li>
                      <Dropdown.Item
                        onSelect={handleShowGroup}
                        active={showGroupField}
                        aria-label="Group"
                        id="group-code"
                      >
                        <span>
                          Group
                          {showGroupField && <FontAwesomeIcon icon="check" />}
                        </span>
                        {showGroupField && (
                          <Form.Group
                            controlId={`groupCode`}
                            className={`discounts-group`}
                          >
                            <Form.Label srOnly>{GROUP_LABEL}</Form.Label>
                            <Form.Control
                              className="input-wrapper"
                              name={`groupCode`}
                              placeholder={`Enter code`}
                              onChange={handleGroupCodeChange}
                              onBlur={handleGroupCodeBlur}
                              defaultValue={groupCode ? groupCode : ""}
                            />
                          </Form.Group>
                        )}
                      </Dropdown.Item>
                    </li>
                  </MemberDiscountList>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Col>

        <Col lg={"auto"}>
          <Button
            type="submit"
            variant="primary"
            className="mt-2 mt-lg-0 w-100"
            onClick={handleUpdateSearch}
            id="update-select-room-criteria-button"
          >
            {"Update"}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
