import Axios from "axios";
import addMinutes from "date-fns/addMinutes";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from ".";
import { TP_REDEMPTION_RATE } from "../@types/Constants";
import { saveRates } from "../redux/slices/Rate/rate";
import { searchSelector } from "../redux/slices/Search/search";
import { RootState } from "../redux/store";
import {
  buildFetchRatesUrl,
  fetchRoomsAndRatesASE,
  getCRSRatesASE,
} from "../services/rates";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useRoomsRates = (index: string | number, editCheckout?: any) => {
  const dispatch = useDispatch();
  const freshCheckout = useAppSelector((state: RootState) => state.checkout);
  const checkout = editCheckout || freshCheckout;
  const search = useAppSelector(searchSelector);
  const [apiUrl, setApiUrl] = useState<string | null>(null);
  const [loadingRates, setLoadingRates] = useState(false);
  const [rooms, setRooms] = useState<Array<any> | null>(null);
  const [roomRates, setRoomRates] = useState<Array<any> | null>(null);
  const [queryHotel, setQueryHotel] = useState<any>(null);
  let discount: any = null;
  let ratePlanFilterCode: any = null;

  if (editCheckout) {
    discount = editCheckout.discount;
  } else {
    discount = search.discount;
    ratePlanFilterCode = search.ratePlanFilterCode;
  }
  // ASE - Availibilty Call
  useEffect(() => {
    if (checkout.Rooms && checkout.Rooms[index]) {
      const newApiUrl = buildFetchRatesUrl(
        checkout.Crs,
        checkout.HotelCode,
        checkout.Start,
        checkout.End,
        checkout.Rooms[index].adults,
        checkout.Rooms[index].children,
        checkout.promotionCode,
        checkout.groupCode,
        null,
        checkout.Rooms[index].childrenAges,
        ratePlanFilterCode,
        discount,
        checkout.EditReservationRate ? checkout.EditReservationRate : null
      );
      setApiUrl(newApiUrl);
    }
  }, [checkout, index]);

  // Fetch pricing.
  useEffect(() => {
    const source = Axios.CancelToken.source();
    let didCancel = false;

    const fetchRates = async (apiUrl: string) => {
      let fetchedRooms: Array<any> | null = null;
      const data = await getCRSRatesASE(
        checkout.Rooms[index]?.adults || 1,
        checkout.Rooms[index]?.children || 0,
        checkout.Rooms[index]?.childrenAges,
        checkout.HotelCode,
        checkout.chainId,
        checkout.Start,
        checkout.End,
        "NonRoom",
        checkout.EditReservationRate
          ? TP_REDEMPTION_RATE.includes(checkout.EditReservationRate)
            ? ""
            : checkout.EditReservationRate
          : checkout.promotionCode,
        checkout.EditReservationRate
          ? TP_REDEMPTION_RATE.includes(checkout.EditReservationRate)
            ? ""
            : checkout.EditReservationRate
          : checkout.groupCode || checkout.rateCode,
        discount || ratePlanFilterCode || "Retail"
      );
      if (data) {
        const rates = {
          Name: data.name,
          Rooms: data.rooms,
          redemptionItem: data.redemptionItem,
          hotelLocation: data?.hotelLocation,
          Brand: data?.brand.code,
        };
        dispatch(
          saveRates({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...rates,
            crsCode: checkout.HotelCode,
            expireCache: rates !== null ? addMinutes(new Date(), 5) : null,
            searchQuery: rates !== null ? apiUrl : null,
          })
        );
      }
      if (data !== null) {
        fetchedRooms = data.rooms;
      }
      if (!didCancel) {
        const thisRoomRates = fetchRoomsAndRatesASE(
          fetchedRooms,
          checkout.discount,
          checkout.promotionCode,
          checkout.groupCode,
          checkout?.Start,
          checkout?.End,
          data?.redemptionItem
        );
        setLoadingRates(false);
        setRoomRates(thisRoomRates);
        setRooms(fetchedRooms);
        setQueryHotel(data);
      }
    };

    (async () => {
      setLoadingRates(true);
      if (apiUrl) {
        await fetchRates(apiUrl);
        if (!didCancel) {
          setLoadingRates(false);
        }
      }
    })();

    return () => {
      didCancel = true;
      source.cancel(`Request cancelled for fetch rooms rates for ${index}`);
    };
  }, [apiUrl, checkout, index]);

  return [loadingRates, rooms, roomRates, queryHotel];
};
