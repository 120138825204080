import React, { useEffect, useState } from "react";
import Layout from "../../components/global/Layout/Layout";
import SEO from "../../components/global/SEO/SEO";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  EditReservationHeader,
  StyledContainer,
} from "../../pageHelpers/EditReservation/EditReservationHelpers";
import NewSelectRoom from "../../components/reservations/NewSelectRoom/NewSelectRoom";
import useMobileDetect from "../../hooks/useMobileDetect";
import NewReservationDetails from "../../components/reservations/NewReservationDetails/NewReservationDetails";
import { setEditReservationCheckout } from "../../redux/slices/Checkout/editReservationCheckout";
import { isEmpty } from "lodash";
import { navigate } from "gatsby";

const NewEditReservation: React.FC<any> = () => {
  const isMobile = useMobileDetect();
  const dispatch = useAppDispatch();

  const editReservationCheckout = useAppSelector(
    (state) => state.editReservationCheckout
  );
  const reservation =
    editReservationCheckout?.originalReservation?.reservation || null;
  const hotel = editReservationCheckout?.originalReservation?.hotel || null;

  useEffect(() => {
    if (isEmpty(reservation)) {
      navigate(-1);
    }
  }, []);

  const handleChangeDate = () => {
    dispatch(
      setEditReservationCheckout({
        ...editReservationCheckout,
        Step: "select-room",
        isModalOpen: !editReservationCheckout.isModalOpen,
      })
    );
  };

  return (
    <Layout isEditReservationPage={true}>
      <SEO
        title={"Edit Reservation"}
        koddiTitle="Edit Reservation"
        noIndex={true}
        noFollow={true}
      />

      {reservation && hotel ? (
        <>
          {isMobile && (
            <NewReservationDetails
              step={"select-room"}
              reservation={reservation}
              hotel={hotel}
              handleChangeDate={handleChangeDate}
            />
          )}
          <StyledContainer>
            <EditReservationHeader step={"select-room"} />
            <NewSelectRoom
              reservation={reservation}
              updateModalView={handleChangeDate}
              showUpdateCriteriaModal={editReservationCheckout.isModalOpen}
            />
          </StyledContainer>
        </>
      ) : (
        <div>Please provide reservation details</div>
      )}
    </Layout>
  );
};

export default NewEditReservation;
