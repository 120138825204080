import * as React from "react";
import { styledContainer, styledModal } from "./UpdateSearchModal.module.scss";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import useMobileDetect from "../../../../hooks/useMobileDetect";

export const StyledModal: React.FC<any> = (props) => {
  const isMobileOnly = useMobileDetect();
  return (
    <Modal
      backdrop={isMobileOnly ? false : true}
      {...props}
      className={styledModal}
    >
      {props.children}
    </Modal>
  );
};

export const StyledContainer: React.FC<{ children: JSX.Element }> = (props) => {
  return <Container className={styledContainer}>{props.children}</Container>;
};
